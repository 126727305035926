import { Highlight, connectRefinementList } from 'react-instantsearch-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import 'react-lazy-load-image-component/src/effects/blur.css';
import "../RefinementList/RefinementList.css"

const RefinementList = ({
  items,
  isFromSearch,
  refine,
  createURL,
}) => (
  <div className='p-0 row' id='refinement_list_container'>

    {items.map(item => (
        
      <div key={item.label} className="col-4 mb-4">
        <a href={createURL(item.value)} onClick={event => {event.preventDefault();refine(item.value);}}>

          {isFromSearch ? 
            (
                <Highlight attribute="label" hit={item} />
            ) 
            : 
            (
            <div id="refinement_list__">
                <div className="container px-0">
                    <div className={`refinement_list__ rounded d-flex flex-column align-items-center pb-1 ${item.isRefined ?'select_1' : ''}`}>

                        <div className={`count_section ${item.isRefined ?'select_2' : ''}`}>
                            <span>{item.count}</span>
                        </div>
                        <div className="img_sec">
                            <LazyLoadImage effect='blur' src={"https://mobo.uk/afh/assets/custom/img/common/placeholder.jpg"} className="img-fluid" width={100} height={100}/>
                        </div>
                        <div className="brand_name">
                            {item.label.split("##")[0].substring(0, 6)}...
                        </div>


                    </div>
                </div> 
            </div>
            )}
            {
                
            }
            {/* <div className="count">
                ({item.count})
            </div> */}
            
        </a>
      </div>
      
    ))}
  </div>
);

export default connectRefinementList(RefinementList);