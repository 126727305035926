import React, { Component } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import Hit from '../Hit/Hit';
import Loader from '../Loader/Loader'
import State from "../States/States"
import "../InfiniteHits/InfiniteHits.css"


class InfiniteHits extends Component {
  static propTypes = {
    hits: PropTypes.arrayOf(PropTypes.object).isRequired,
    hasMore: PropTypes.bool.isRequired,
    refineNext: PropTypes.func.isRequired,
  };

  sentinel = null;

  onSentinelIntersection = entries => {
    const { hasMore, refineNext } = this.props;

    entries.forEach(entry => {
      if (entry.isIntersecting && hasMore) {
        refineNext();
      }
    });
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(this.onSentinelIntersection);

    this.observer.observe(this.sentinel);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }



  

  render() {
    const { hits,hasMore } = this.props;
    let num = 1;
    // console.log(hasMore);
    

    return (
      <>
      {/* {hits.length === 0  &&
        ("No Result") } */}

        <div className="ais-InfiniteHits ">
          <div className="ais-InfiniteHits-list row equal-cols" >
            
            {hits.map(hit => (
                <div className="col-4" key={hit.id}>
                  <Hit hit={hit} hw={num++}/>  
                </div>
            )) }

            <li className="ais-InfiniteHits-sentinel d-flex justify-content-center mt-3" ref={c => (this.sentinel = c)}
            >
              <p>{hasMore ? <Loader/> : ""}</p>
              


              
              {/* {hits.length == num+1 ? " " : <Loader/>} */}
              
            </li>
          </div>
        </div>

        
      </>
    );
  }
}

export default connectInfiniteHits(InfiniteHits);
