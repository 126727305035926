import React,{useState} from 'react'
import brandLogo from '../../image/logo/logo.svg'
import { FiSearch,FiShoppingCart } from "react-icons/fi";
import SlidebarRight from '../SlidebarRight/SlidebarRight';
// Typesense Component import
import CustomSearchBox from '../../component/SearchBox/SearchBox' 

// CSS
import '../Header/Header.css'


function Header() {

    const [click, setClick] = useState(false);

    const handleCLick = () =>{
        // let change = (e.target.getAttribute("data-value"))
        console.log(click);
        setClick(!click)
        // console.log(click);
    }

  return (
    <header>
        <div id="header" className='py-3'>
            <div className="container">
                <div className="header row">
                    <div className="col-3 d-flex align-items-center">
                        <div className="header_logo">
                            <img src={brandLogo} alt="" width={200}/>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="search_box_container d-flex w-100">
                            <CustomSearchBox/>
                        </div>
                    </div>
                    <div className="col-3 d-flex justify-content-end align-items-center">
                        <div className="checkout_icon d-flex justify-content-end align-items-center" >
                            <div onClick={handleCLick} >
                                {/* <p></p> */}
                                <FiShoppingCart/>
                            </div>
                                
                            {/* <span className='count'>1</span> */}
                        </div>
                    </div>                 
                </div>
            </div>
        </div>
        {/* {click && <SlidebarRight/>} */}
        
    </header>
  )
}

export default Header