import React from 'react'
import TypeSense from '../../component/TypeSense/TypeSense'

function Home() {
  return (
    <>
        <TypeSense/>
    </>
  )
}

export default Home