import React from 'react'
import {ClearRefinements} from 'react-instantsearch-dom';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react"; 

import CustomRangeSlider from '../../component/RangeSlider/RangeSlider';
import CustomRefinementList from '../../component/RefinementList/RefinementList';
import CustomRefinementSubCategory from '../../component/RefinementSubCategory/RefinementSubCategory';





import '../HomeLeftSide/HomeLeftSide.css'


function HomeLeftSide() {
  return (
    

        <div id='home_left_side' className='position-relative'>
            <div className="home_left_side">
                <OverlayScrollbarsComponent>
                <div>
                    <div className="accordion " id="accordionPanelsStayOpenExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                Price Range
                            </button>
                            </h2>
                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                            <div className="accordion-body">
                                <div className='  w-100 py-3 mb-3'>
                                    <CustomRangeSlider attribute="starting_price" precision= "2" step="5" tooltip="true"/>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="divided"></div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                Brand
                            </button>
                            </h2>
                            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingTwo">
                            <div className="accordion-body">
                                <div className='refine_ w-100 py-3'>
                                    {/* <RefinementList attribute="brand" searchable={false}/> */}
                                    <CustomRefinementList attribute="brand" limit={50}/>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="divided"></div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                Sub category
                            </button>
                            </h2>
                            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingThree">
                            <div className="accordion-body">
                                {/* <RefinementList attribute="sub_category" searchable={false}/> */}
                                <CustomRefinementSubCategory attribute="sub_category"/>
                            </div>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className="clear_filter_container pt-0">
                        <div className="divided mb-3"></div>
                        <div className="clear_filter">
                            <ClearRefinements translations={{reset: 'Reset'}}/>
                        </div>
                    </div>
                </div>
                </OverlayScrollbarsComponent>            
            </div>
        </div> 
        

  )
}

export default HomeLeftSide