import {useState} from 'react';
import { connectSortBy } from 'react-instantsearch-dom';
import ri from '../../image/sortby_icon/relevant-icon.svg'
import ci from '../../image/sortby_icon/cheapest-icon.svg'
import ni from '../../image/sortby_icon/nearest-icon.svg'
import { FiChevronDown } from "react-icons/fi";

import "../SortBy/SortBy.css"




const SortBy = ({ items, refine, createURL }) => {

  const [label, setLabel] = useState("Relevant results");

  let handleDivClicked = (e) => {
    // console.log(e.target.getAttribute("data-label"));

    let Label = e.target.getAttribute("data-label") 
    console.log(Label);
    setLabel(Label);
  }


  
return(
  <div id='sort_by_' className='mb-3 w-100 position-relative'>
    <a className="btn dropdown-toggle bs" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" >
        <span className='sort'>Sort :</span> <span className='me-1'>{label}</span><FiChevronDown />
    </a>

    <ul className='dropdown-menu bs w-100'>

      {items.map((item,index) => (
        <li key={item.value}  onClick={ (e) => handleDivClicked(e)}>
          <a className='dropdown-item d-flex' data-label={item.label} href={createURL(item.value)} onClick={event => { event.preventDefault(); refine(item.value);}}>
            <div className='d-flex align-items-end'>
            <img src={index==0? ri : index==1 ? ci : ni} alt="" width={26} height={26} className="img-fluid pe-2"/>
            </div>
            
            {item.label}
              
          </a>
        </li>
      ))}
    </ul>
  </div>

)

};

export default connectSortBy(SortBy);