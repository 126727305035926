import React from 'react';
import PropTypes from 'prop-types';
import {connectHits} from 'react-instantsearch-dom';
import { FiMapPin,FiShoppingCart } from "react-icons/fi";
import OwlCarousel from 'react-owl-carousel2';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-owl-carousel2/lib/styles.css';
import 'react-owl-carousel2/src/owl.theme.default.css';


import '../Hit/Hit.css'

const options = {
  items: 1,
  nav: true,
  rewind: true,
  autoplay: true
};

const Hit = ( { hit, hw }) => (
  
  <div className='hit__' id='hit__'>

        <div className=' mb-3' key={hit.id}>
            <div className="box_sec container_bg">
              <div className='img_sec'>
                 
                 <div>{hit.xl_webp_images == '' ? 
                 (<LazyLoadImage effect="blur" src={"https://mobo.uk/afh/assets/custom/img/common/placeholder.jpg"} alt="The Last of us"/>) 
                 : 
                 (
                  <OwlCarousel options={options}>
                   {hit.xl_webp_images.map( item =>
                    <div><img src={"https://mobo.uk/afh/assets/custom/img/common/placeholder.jpg" }  alt="The Last of us" className='img-fluid'/></div>
                   )}
                  </OwlCarousel>

                 )}</div>

              </div>
              <div className="content_section px-3 py-3">
                {/* <p><b>Result No: {hw}</b>(test)</p> */}
                <div className='title_sec'>
                  <h2>{hit.title.substring(0,20)}...</h2> 
                </div>

                <div className="distance__">
                  <p><FiMapPin/>236 miles away</p>
                </div>

                <div className="car_details d-flex flex-wrap">

                  <div className="card__ d-flex align-items-center">
                    <LazyLoadImage effect="blur" src={"https://mobo.uk/afh/assets/img/fuel.png"} alt="" width={20} height={20} />
                    <span className='ps-2'>Diesel</span>
                  </div>

                  <div className="card__ d-flex align-items-center">
                    <LazyLoadImage effect="blur" src={"https://mobo.uk/afh/assets/img/person.png"} alt="" width={20} height={20} />
                    <span className='ps-2'>5</span>
                  </div>

                  <div className="card__ d-flex align-items-center">
                    <LazyLoadImage effect="blur" src={"https://mobo.uk/afh/assets/img/car.png"} alt="" width={20} height={20} />
                    <span className='ps-2'>SUV</span>
                  </div>

                  <div className="card__ d-flex align-items-center">
                    <LazyLoadImage effect="blur" src={"https://mobo.uk/afh/assets/img/airbag.png"} alt="" width={20} height={20} />
                    <span className='ps-2'>Air Bags</span>
                  </div>

                  <div className="card__ d-flex align-items-center">
                    <LazyLoadImage effect="blur" src={"https://mobo.uk/afh/assets/img/gear.png"} alt="" width={20} height={20} />
                    <span className='ps-2'>Auto</span>
                  </div>

                </div>

                <div className="bottom_section mt-4 d-flex justify-content-between align-items-center">
                  <div className="starting_price ">
                    <h5 className='pry mb-0'>£{hit.starting_price} </h5> 
                  </div>
                  <div className='button_section'>
                    <button className='sec_btn'>More</button>
                    <button className='pry_btn'><FiShoppingCart/>Add</button>
                  </div>
                </div>


        
                
              </div>


              
            </div>
        </div>

    </div> 
//   </div>
);

// function Hits() {
//     return <Hit/>
// }

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default connectHits(Hit);   
