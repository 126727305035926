import { connectSearchBox } from 'react-instantsearch-dom';
import '../SearchBox/SearchBox.css'
import { FiSearch } from "react-icons/fi";

const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (

  <div className="search_box_container w-100" id='search__'>
    <form noValidate action="" role="search">
      <input
        type="search"
        value={currentRefinement}
        onChange={event => refine(event.currentTarget.value)}
        placeholder="What would you like to hire?"
        className='w-100'
      />
      <div className="search_box">
        <FiSearch/>
      </div>
      
      {/* <button onClick={() => refine('')}>Reset query</button> */}
      {/* {isSearchStalled ? 'My search is stalled' : ''} */}
    </form>
  </div>

);

export default connectSearchBox(SearchBox)