import React from 'react'
import '../Loader/Loader.css'

function Loader() {
  return (
    <div id="loader">
        <ul className="loader">
            <li className="loader__item"></li>
            <li className="loader__item"></li>
            <li className="loader__item"></li>
        </ul>
    </div>
  )
}

export default Loader