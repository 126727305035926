import React,{Component} from 'react';
import "../../App.css"

//Typesense Components Import
import {InstantSearch,Configure,SortBy,Pagination} from 'react-instantsearch-dom';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';

//Custom Typesense Components Import
import CustomInfiniteHits from '../../component/InfiniteHits/InfiniteHits'
import CustomStats from '../../component/States/States'  
import Header from '../Header/Header';
import HomeLeftSide from '../HomeLeftSide/HomeLeftSide';
import CustomSortBy from '../SortBy/SortBy' 
import CustomHit from '../Hit/Hit'

import "../TypeSense/TypeSense.css"






//  function TypeSense() {

  // Typesense Configuration section

const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
  server: {
    apiKey: "a1rnhlayn0pnXErfRckgVO0iTWuNPeXx",
    nodes: [
      {
        host: "d3aypfog0elm5hb6p-1.a1.typesense.net",
        port: '443',
        protocol: 'https',
      },
    ],
    
  },
  additionalSearchParameters: {query_by: "title,keywords,category", facet_by: "category", highlight_fields: "title"}
  ,
});
const searchClient = typesenseInstantsearchAdapter.searchClient;

//End Typesense Configuration section

class TypeSense extends Component {
  render() {
    return (
      <div className="ais-InstantSearch">
      <div className='output_container'>


      <InstantSearch indexName="products" searchClient={searchClient}>
          
      <Header/>


      <main className='py-4 container_bg vh90'>
        <div className="container">
          <div className="row">
              <div className="col-3">
                  <HomeLeftSide/>
              </div>
              


              <div className="col-9">
                <div className="hit_show right_side">
                  <div className="hits_header d-flex justify-content-between">

                    {/* Stats */}
                    <div className="total_count">
                      <CustomStats />
                    </div>

                    {/* Sortby */}
                    <div className="sort__">
                      <CustomSortBy 
                      defaultRefinement="products"

                        items={[
                          { label: 'Relevant results', value: 'products' },
                          { label: 'Cheapest price', value: 'products/sort/starting_price:asc' },
                          { label: 'Nearest to me', value: 'products/sort/starting_price:desc' },
                        ]}
                      
                      />


                      {/* <SortBy
                        defaultRefinement="products"
                        items={[
                          { label: 'Relevant results', value: 'products' },
                          { label: 'Cheapest price', value: 'products/sort/starting_price:asc' },
                          { label: 'Nearest to me', value: 'products/sort/starting_price:desc' },
                        ]}
                      /> */}
                      
                    </div>
                  </div>

                  <CustomInfiniteHits minHitsPerPage={12}/>
                  {/* <CustomHit /> */}
                  <Configure hitsPerPage={12} filters='status: 1'/>

                </div>
              </div>
            </div>
          </div>
      </main>

      </InstantSearch>
      </div>
      </div>
    );
  }
}
  
  // return (
  //   <div>
      

  //   </div>
  // );
// }

export default TypeSense