import { Highlight, connectRefinementList } from 'react-instantsearch-dom';
import "../RefinementSubCategory/RefinementSubCategory.css"




const RefinementSubCategory = ({items,isFromSearch,refine,createURL}) => (
    
    
  <div className='p-0' id='refinement_sc_container'>
<form>
    {items.map(item => (
        
      <div key={item.label} className=" mb-2">
        <a href={createURL(item.value)} onClick={event => {event.preventDefault();refine(item.value);}}>

          {isFromSearch ? 
            (
                <Highlight attribute="label" hit={item} />
            ) 
            : 
            (
            <div id="refinement_sc__">
                <div className="container px-0">
                    <div className="refinement_sc__ rounded py-1">

                        
                        {/* <div className="brand_name">
                            {item.label.split("##")[0]}...
                        </div>
                        <div className="count_section">
                            <span>{item.count}</span>
                        </div> */}

                        <div className="form-group mb-0 d-flex justify-content-between">
                            <div className="top_section">
                                <input type="checkbox" id={item.count} checked={item.isRefined ? true : false}/>
                                <label htmlFor={item.count}>{item.label.split("##")[0]}...</label>
                            </div>
                            <div className='bottom_section'>
                                <span className={`count ${item.isRefined ?'select' : ''}`}>{item.count}</span>
                            </div>
                        </div>

                        {/* <input type="checkbox" {item.isRefined ? 'checked' : ''} className="form-check-input" id="check{item.count}" /> */}
                        {/* <label className="form-check-label cus-nowrap pe-5" for="check ${item.count}"> {item.label.split("##")[1].substring(0, 3)} </label>
                        <div>
                        <span className="badge">{item.count}</span>
                        </div> */}


                    </div>
                </div> 
            </div>
            )}
            {
                
            }
            {/* <div className="count">
                ({item.count})
            </div> */}
            
        </a>
      </div>
      
    ))}
    </form>
  </div>
);

export default connectRefinementList(RefinementSubCategory);