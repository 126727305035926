// import React,{useState,useEffect} from 'react'
import Home from './container/Home/Home'
// import Loader from './component/Loader/Loader'

function App() {
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false) 
  //   }, 2000);
  // },[])

  return (
    <>
      <div className='App'>

        <Home/>
        
      
      </div>

      
    </>
  )
}

export default App